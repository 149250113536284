import React, { useState } from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'

import {
  SectionHero,
  SectionAbout,
  SectionPayment,
  SectionBackground,
} from '../../partials/ChargeManagementPage'

import {
  SectionFAQ,
  SectionStepsRegister,
  SectionStores,
  SideSection,
} from '../../partials/commons'

import faq from '../../data/faq-charge-management'
import getStaticImage from '../../hooks/getStaticImage'

const GestaoDeCobrancasPage = (): JSX.Element => {
  const [toggleForm, setToggleForm] = useState(false)

  const toggle = (): void => {
    setToggleForm(true)
  }

  const schemaImg = getStaticImage('cora-gestao-cobrancas.png')
  const schema = {
    title: 'Gestão de cobrança',
    images: [schemaImg],
    type: 'Product',
    description:
      'Gestão de cobrança para empresas com acesso a funcionalidades como, dashboard, notificações automáticas de cobrança, boletos parcelados e cobranças com descontos, além de boletos gratuitos e ilimitados no aplicativo',
  }

  return (
    <Layout pageType="gestao-de-cobranca" toggle={toggle}>
      <SEO
        title="Gestão de cobranças completa, gratuita e descomplicada - Cora"
        description="Cobrar clientes nunca foi tão fácil. Gestão de Cobranças na Cora: boletos gratuitos, notificação de cobrança automática e mais. Abra sua conta"
        schema={schema}
        linkCanonical="conta-pj/gestao-de-cobrancas"
      />
      <SectionHero toggle={toggle} />
      <SectionAbout />
      <SectionPayment />
      <SectionBackground />
      <SectionStepsRegister />
      <SectionFAQ data={faq} />
      <SectionStores
        title="Cadastre sua empresa"
        text="É gratuita e fácil de abrir."
        linkText="Tão simples quanto baixar um app."
        pageType="gestao-de-cobranca"
      />
      <SideSection
        toggleForm={toggleForm}
        setToggleForm={setToggleForm}
        idFinishForm="FormFinishedGestaodeCobrancas"
        pageType="gestao-de-cobranca"
      />
    </Layout>
  )
}

export default GestaoDeCobrancasPage
